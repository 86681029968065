import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useNavigate } from "react-router-dom";
import { handleStepCompletion } from '../../components/user-workflow/user-workflow-controller';


export default function OnboardingWelcomePage(props: any) {

	document.title = 'Welcome to Maximize | Maximize Project';

	const navigate = useNavigate();

	const progress = 5;

	useEffect(() => {

	}, []);

	const handleClick = () => {
		let request = {
			workflowName: 'Onboarding',
			stepNumber: 1
		}
		handleStepCompletion(request);
	}

	return (
		<div className="onboarding-page">
			<div>
				<div className="progress-bar-container">

				</div>
				<div className="onboarding-welcome">

					<Row className="justify-content-center">
						<Col xs={12} lg={9} xl={7}>
							<h1>Welcome to Maximize!</h1>

							<p>The Maximize platform is here to promote your professional growth and boost classroom success.</p>

							<Row className="justify-content-center bullet-list">
								<Col xs={12}>
									<div className="bullet-item">
										<div>
											<h3>Professional Growth</h3>
											<p>Reflect on your practices and identify areas for improvement. Set specific goals at your own pace and monitor your progress.</p>
										</div>
									</div>

									<div className="bullet-item">
										<div>
											<h3>Strategies and Resources</h3>
											<p>Access tools to create a supportive learning environment that nurtures academic and social-emotional growth for <strong><i>all</i></strong> students and to respond effectively to challenging situations.</p>
										</div>
									</div>

									<div className="bullet-item">
										<div>
											<h3>Collaboration</h3>
											<p>Strengthen your connections with colleagues through enhanced communication and collaboration tools.</p>
										</div>
									</div>

									<div className="bullet-item">
										<div>
											<h3>Continuing Education</h3>
											<p>Earn contact hours for your learning activities.</p>
										</div>
									</div>

								</Col>
							</Row>
							
						</Col>
					</Row>
				</div>
			</div>
			<div className="action-button-container">
				<Container>
					<Row className="justify-content-center">
						<Col xs={12} xl={9}>
							<div className="d-flex justify-content-between">
								<Button variant="secondary" onClick={() => navigate('/logout')}>Log Out</Button>
								<Button variant="primary" onClick={handleClick}>Get started</Button>
							</div>
						</Col>
					</Row>
				</Container>

			</div>
		</div>
	)
}
