import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from "react-router-dom";
import { format, parseISO, differenceInDays } from 'date-fns';

import EditReflection from './edit-reflection';
import DeleteReflection from './delete-reflection';

import { ReactComponent as CheckIcon } from '../../images/icons/check.svg';
import { ReactComponent as DiamondIcon } from '../../images/icons/diamond.svg';
import { ReactComponent as WaterIcon } from '../../images/icons/water.svg';
import { ReactComponent as SunIcon } from '../../images/icons/sun.svg';

export default function ReflectionCard(props: any) {

	let user = JSON.parse(localStorage.getItem('user')!);
	let isAdmin = user.is_admin;
	let navigate = useNavigate();

	const [surveyInProgress, setSurveyInProgress] = useState<any>();
	const [count, setCount] = useState<number>(0);

	useEffect(() => {
		checkInProgress();
	}, []);

	const checkInProgress = () => {
		let inProgress = props.mySurveys.find((survey: any) => survey.survey_id === props.reflection.survey_id);
		if (inProgress) {
			setSurveyInProgress(inProgress);
			calculateExpiry(inProgress);
		}
	};

	const calculateExpiry = (survey: any) => {
		let start = parseISO(survey.created_at);
		let today = new Date();
		if (differenceInDays(today, start) > -8) {
			setCount(differenceInDays(today, start));
		}
	}

	const handleReload = () => {
		props.handleReload(true);
	}

	const handleContinueSurvey = () => {
		if (surveyInProgress) {
			localStorage.setItem('session_id', surveyInProgress.session_id);
		}
		navigate('/survey/' + props.reflection.survey_id, { state: {} });
	}

	return (
		<Col xs={12}>
			<div className={'reflection-card ' + (props.complete ? 'complete ' : '') + (surveyInProgress && !props.complete ? 'in-progress ' : '')}>
				<Card>
					{
						props.complete ?
							<div className="status-message">
								Complete <CheckIcon className="ms-2" />
							</div>
							:
							<>
								<div className="status-message">
									<SunIcon className="me-2" /> +0.25
								</div>
							</>
					}
					<Card.Body>
						<h3>{props.reflection.name}</h3>

						{
							props.reflection.strategy ?
								<p><strong>Strategy:</strong> {props.reflection.strategy.strategy.name}</p>
								:
								<p className="d-flex align-items-center">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#F6CA56" className="bi bi-star-fill me-2" viewBox="0 0 16 16">
										<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
									</svg>
									<strong>Bonus Activity</strong>
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#F6CA56" className="bi bi-star-fill ms-2" viewBox="0 0 16 16">
										<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
									</svg>
								</p>
						}
						<p className="mb-4">{props.reflection.description}</p>
						<div className="card-actions">

							{surveyInProgress ?
								<Button className="btn btn-primary" role="button" onClick={handleContinueSurvey}>Continue Activity</Button>
								:
								<Link to={'/survey/' + props.reflection.survey_id} className="btn btn-primary" role="button" >Start Activity</Link>
							}

							<Link className="ms-2 btn btn-secondary" role="button" target="_blank" to={'https://learn.maximizeproject.app/' + (props.reflection.strategy ? props.reflection.strategy.strategy.page_id : '')}>Learn more</Link>

							{isAdmin ?
								<>
									<EditReflection reflection={props.reflection} handleReload={handleReload} />
									<DeleteReflection reflection={props.reflection} handleReload={handleReload} />
								</>
								:
								<></>
							}

							{surveyInProgress &&
								<p>
									{(count < 6) &&
										<span className="text-danger"><strong>You have {7 - count} day{count === 6 ? '' : 's'} to complete this activity, or you will have to start over.</strong></span>
									}
								</p>
							}
						</div>


					</Card.Body>
				</Card>
			</div>
		</Col>
	)
}

