import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
import Modal from 'react-bootstrap/Modal';

import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

import { getUser } from '../../_services/user';
import { generateCertificate } from '../../_services/report';
import { getAllCoachTeacherEntries } from '../../_services/coach-teacher';
import { approveEntry, deleteEntry } from '../../_services/coach-teacher';

import StrategyChart from './strategy-chart';
import StrategyTable from '../strategy-table';
import UserGoals from '../user-goals';
import Meetings from '../meetings';
import Observations from '../observations';
import MyCoaches from '../my-coaches';


import { ReactComponent as DiamondIcon } from '../../images/icons/diamond.svg';
import { ReactComponent as StarIcon } from '../../images/icons/star.svg';
import { ReactComponent as LightbulbIcon } from '../../images/icons/lightbulb.svg';
import { ReactComponent as SunIcon } from '../../images/icons/sun.svg';
import { ReactComponent as WaterIcon } from '../../images/icons/water.svg';

export default function Profile(props: any) {


  const [isReady, setIsReady] = useState<any>(false);
  const [certificateProcessing, setCertificateProcessing] = useState<boolean>(false);

  const [user, setUser] = useState<any>(null);
  const [goalCount, setGoalCount] = useState<any>(null);
  const [reflectionCount, setReflectionCount] = useState<any>(null);
  const [assessmentCount, setAssessmentCount] = useState<any>(null);
  const [gemCount, setGemCount] = useState<any>(null);
  const [coachTeacherEntries, setCoachTeacherEntries] = useState<any>([]);
  const [coachRequestElements, setCoachRequestElements] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCoachingUI, setShowCoachingUI] = useState<boolean>(false);

  let userJSON = localStorage.getItem('user');
  let authUser = userJSON !== null ? JSON.parse(userJSON) : {};
  let isAdmin = authUser !== null ? authUser.is_admin : false;
  let isCoach = authUser !== null ? authUser.is_coach : false;

  useEffect(() => {
    getUserData();
    getCoachRequests();
  }, []);

  useEffect(() => {
    if (user !== null) {
      //console.log('user', user);
      checkConnections();
      countReflections();
      countCompletedGoals();
      countSelfAssessments();
    }
  }, [user]);

  useEffect(() => {
    if (reflectionCount !== null && goalCount !== null && assessmentCount !== null) {
      countGems();
    }
  }, [reflectionCount, goalCount, assessmentCount]);

  useEffect(() => {
    if (coachTeacherEntries.length > 0) {
      buildCoachRequestElements();
    } else {
      setShowModal(false);
    }
  }, [coachTeacherEntries]);

  const checkConnections = () => {
    if (user.coach_teacher_entries.length > 0) {
      user.coach_teacher_entries.forEach((item: any) => {
        if (item.is_approved && item.coach_id === authUser.id) {
          setShowCoachingUI(true);
        }
      });
    } else {
      setShowCoachingUI(false);
    }
  }

  const buildCoachRequestElements = () => {
    let el = coachTeacherEntries.map((entry: any, index: number) => {
      return (
        <div className="mb-2" key={index}>
          <span className="goal-statement">
            <h5> {entry.coach.first_name} {entry.coach.last_name} would like to connect with you.</h5>
            <p className="mb-0">This will allow {entry.coach.first_name} to review your active and completed goals in Maximize. You can revoke this access at any time.</p>
          </span>
          <div className="d-flex justify-content-between mt-3">
            <Button variant={'danger'} className="me-2" onClick={() => handleDeny(entry.id)}>Deny</Button>
            <Button variant={'primary'} onClick={() => handleApprove(entry.id)}>Approve</Button>
          </div>
        </div>
      )
    });
    setCoachRequestElements(el);
    setShowModal(true);
  }

  const handleApprove = (id: number) => {
    let request = {
      is_approved: true
    }
    approveEntry(id, request).then(resp => {
      removeCoachRequestEntry(id);
    }).catch(err => {
      console.log(err);
    });
  }

  const handleDeny = (id: number) => {
    deleteEntry(id).then(resp => {
      removeCoachRequestEntry(id);
    }).catch(err => {
      console.log(err);
    });
  }

  const removeCoachRequestEntry = (id: number) => {
    let entries = coachTeacherEntries.filter((entry: any) => {
      return entry.id !== id;
    });
    setCoachTeacherEntries(entries);
  }

  const handleModalOpen = () => {
    setShowModal(true);
  }

  const handleModalClose = () => {
    setShowModal(false);
  }

  const countReflections = () => {
    const cutoffDate = getCutoffStartDate();
    let count = 0;
    user.reflections.forEach((reflection: any) => {
      const reflectionDate = new Date(reflection.created_at);
      if (reflectionDate > cutoffDate) {
        count++;
      }
    });
    setReflectionCount(count);
  }

  const countCompletedGoals = () => {
    const cutoffDate = getCutoffStartDate();
    let count = 0;
    user.goals.forEach((goal: any) => {
      const goalDate = new Date(goal.created_at);
      if (goal.is_complete && goalDate > cutoffDate) {
        count++;
      }
    });
    setGoalCount(count);
  }

  const countSelfAssessments = () => {
    let arr: number[] = [];
    user.strategy_scores.forEach((score: any) => {
      const scoreDate = new Date(score.created_at);
      if (!arr.includes(score.self_assessment_id) && scoreDate > getCutoffStartDate()) {
        arr.push(score.self_assessment_id);
      }
    });
    setAssessmentCount(arr.length);
  }

  const countGems = () => {
    let n = (reflectionCount + assessmentCount) * .25;
    if (n > 10) {
      n = 10;
    }
    setGemCount(n);
  }


  const getUserData = () => {
    getUser(props.userId).then((resp: any) => {
      setUser(resp.data.data);
    }).catch(err => {
      console.log(err);
    });
  }

  const getCoachRequests = () => {
    getAllCoachTeacherEntries('teacher').then(resp => {
      let arr: any = [];
      resp.data.data.forEach((item: any) => {
        if (!item.is_approved) {
          arr.push(item);
        }
      });
      setCoachTeacherEntries(arr);
    }).catch(err => {
      console.log(err);
    });
  }

  const handleGenerateCertificate = () => {
    setCertificateProcessing(true);
    generateCertificate().then(resp => {
      toast.success(resp.data.message);
      setCertificateProcessing(false);
    }).catch(err => {
      console.log(err);
      toast.error('There was an error generating your certificate.');
      setCertificateProcessing(false);
    });
  }

  const getCutoffStartDate = () => {
    const currentYear = new Date().getFullYear();
    const cutOffDate = new Date(`${currentYear}-${process.env.REACT_APP_CUT_OFF_DATE}`);
    const today = new Date();
    let date;
    if (today < cutOffDate) {
      date = new Date(`${currentYear - 1}-${process.env.REACT_APP_CUT_OFF_DATE}`);
    } else {
      date = cutOffDate;
    }
    return date;
  };

  const triggerCoachesReload = () => {
    getUserData();
  }

  return (
    <>

      <div className="profile-container">
        <Row>
          <Col xs={12}>
            <div className="
              d-flex 
              flex-column
              align-items-start 
              justify-content-start 
              flex-sm-row
              align-items-sm-center 
              justify-content-sm-between 
              mb-5
              ">
              {
                user ?
                  <div className="mb-4 mb-md-0">
                    <h1 className="mb-0">{user.first_name} {user.last_name}</h1>
                    <span>Joined {format(user.created_at, 'MMM yyyy')}</span>
                  </div>
                  :
                  <Placeholder as="div" className="profile-header-placeholder" size="lg" />
              }

              <Button variant="primary" onClick={handleGenerateCertificate}>{certificateProcessing ? 'Processing...' : 'Generate Certificate'}</Button>
            </div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col xs={12}>
            <h2>Stats (2024-25)</h2>
          </Col>
          <Col xs={12} md={4} className="mb-4 mb-md-0">
            <div className="panel d-flex align-items-center">
              <div className="icon">
                <SunIcon />
              </div>
              {
                gemCount !== null ?
                  <div>
                    <h3>{gemCount} <span className="text-sm d-inline">/10</span></h3>
                    <span>Contact Hours</span>
                  </div>
                  :
                  <Placeholder as="div" className="stat-placeholder" size="lg" />
              }

            </div>
          </Col>
          <Col xs={12} md={4} className="mb-4 mb-md-0">
            <div className="panel d-flex align-items-center">
              <div className="icon">
                <StarIcon />
              </div>
              {goalCount !== null ?
                <div>
                  <h3>{goalCount}</h3>
                  <span>Goals Completed</span>
                </div>
                :
                <Placeholder as="div" className="stat-placeholder" size="lg" />
              }

            </div>
          </Col>
          <Col xs={12} md={4} className="mb-4 mb-md-0">
            <div className="panel d-flex align-items-center">
              <div className="icon">
                <LightbulbIcon />
              </div>
              {
                reflectionCount !== null ?
                  <div>
                    <h3>{reflectionCount}</h3>
                    <span>Reflections Completed</span>
                  </div>
                  :
                  <Placeholder as="div" className="stat-placeholder" size="lg" />
              }
            </div>
          </Col>
        </Row>

        {user && authUser.id === user.id ?
          <MyCoaches reload={triggerCoachesReload} />
          :
          <></>
        }

        {user && authUser.id === user.id && user.strategy_scores.length > 0 ?
          <Row>
            <Col xs={12} className="mb-5">
              <h2>Self-Assessments</h2>
              {
                user ?
                  <div className="panel">
                    <StrategyChart user={user} />
                  </div>
                  :
                  <Placeholder as="div" className="panel-placeholder" size="lg" />
              }
            </Col>
            <Col xs={12}>
              <StrategyTable scores={user.strategy_scores} />
            </Col>
          </Row>
          :
          <>  </>
        }

        {user ?
          <>
            <Row className="mb-4">
              <Col xs={12}>
                <h2>Active Goals</h2>
                <div>
                  <UserGoals userId={user.id} type={"active"} />
                </div>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col xs={12}>
                <h2>Completed Goals</h2>
                <div>
                  <UserGoals userId={user.id} type={"completed"} />
                </div>
              </Col>
            </Row>
          </>

          :
          <></>
        }


        {
          showCoachingUI ?
            <>
              <Row className="mb-5">
                <Col xs={12}>
                  <h2 className="d-flex justify-content-between">
                    Meetings
                    <Button variant="secondary" size="sm" href={'/meeting-details?teacherId=' + props.userId}>+ New meeting</Button>
                  </h2>
                  <div className="panel">
                    <Meetings userId={props.userId} />
                  </div>
                </Col>
              </Row>

              <Row className="mb-5">
                <Col xs={12}>
                  <h2 className="d-flex justify-content-between">
                    Observations
                    <Button variant="secondary" size="sm" href={'/observation-details?teacherId=' + props.userId}>+ New observation</Button>
                  </h2>
                  <div className="panel">
                    <Observations userId={props.userId} />
                  </div>
                </Col>
              </Row>
            </>
            :
            <></>
        }

        {user && authUser.is_admin ?
          <Row className="mb-5">
            <Col xs={12}>
              <h2>Admin Stuff</h2>
              <div className="panel">
                <Row>
                  <Col xs={12} md={6}>
                    <p className="mb-0"><strong>Email:</strong> {user.email}</p>
                    <p className="mb-0"><strong>School:</strong> {user.school ? user.school : '-'}</p>
                    <p className="mb-0"><strong>Cohort:</strong> {user.cohort ? user.cohort : '-'}</p>
                    <p className="mb-0"><strong>Research Consent:</strong> {user.consents[0] && user.consents[0].status === 1 ? 'Yes' : 'No'}</p>
                  </Col>
                  <Col xs={12} md={6}>
                    <p className="mb-0"><strong>Admin User:</strong> {user.is_admin ? 'Yes' : 'No'}</p>
                    <p className="mb-0"><strong>Team Lead:</strong> {user.is_coach ? 'Yes' : 'No'}</p>
                    <p className="mb-0"><strong>Logins:</strong> {user.login_count}</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          :
          <></>
        }

      </div >

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Connection Requests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {coachRequestElements}
        </Modal.Body>
      </Modal>

    </>
  );
}