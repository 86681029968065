import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, useNavigate } from "react-router-dom";
import { format, parseISO, differenceInDays } from 'date-fns';

import { getSurveyInProgressBySurveyId } from "../../_services/survey-in-progress";

import { ReactComponent as GemIcon } from '../../images/icons/diamond.svg';
import { ReactComponent as SunIcon } from '../../images/icons/sun.svg';


export default function SelfAssessmentPage(props: any) {

    document.title = 'Self-Assessment | Maximize Project';
    const navigate = useNavigate();

    const [surveyInProgress, setSurveyInProgress] = useState<any>();
    const [count, setCount] = useState<number>(0);

    useEffect(() => {
        getMySurveyInProgress();
    }, []);

    useEffect(() => {
        if (surveyInProgress) {
            calculateExpiry(surveyInProgress);
        }
    }, [surveyInProgress]);

    const getMySurveyInProgress = () => {
        getSurveyInProgressBySurveyId(process.env.REACT_APP_SELF_ASSESSMENT_SURVEY_ID!).then((resp: any) => {
            console.log('survey in progress', resp.data.data[0]);
            setSurveyInProgress(resp.data.data[0]);
        }).catch((error: any) => {
            console.log('error', error);
        });
    }

    const calculateExpiry = (survey: any) => {
        let start = parseISO(survey.created_at);
        let today = new Date();
        if (differenceInDays(today, start) > -8) {
            setCount(differenceInDays(today, start));
        }
    }

    return (
        <div className="onboarding-page">
            <div className="onboarding-reflection">
                <Row className="justify-content-center">
                    <Col xs={12} lg={9} xl={8}>
                        <h1>Complete a Self-assessment</h1>
                        <p>Self-assessment is an important part of professional growth. For the 10 Maximize strategies, read the definition of the strategy and answer the questions Your answers will be used to create a profile of strengths and areas for growth. We encourage you to review the areas of growth and set a goal for growth with one strategy.</p>
                        {surveyInProgress &&
                            <p>
                                {(count < 6) &&
                                    <span className="text-danger"><strong>You have {7 - count} day{count === 6 ? '' : 's'} to complete this activity, or you will have to start over.</strong></span>
                                }
                            </p>
                        }
                    </Col>
                </Row>
            </div>
            <div className="action-button-container">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} xl={9}>
                            <div className="d-flex justify-content-between">
                                <Button variant="secondary" onClick={() => navigate('/strategies')}>Back</Button>
                                <div className="d-flex align-items-center">
                                    <div className="me-4 d-flex align-items-center">
                                        <SunIcon className="me-2" />
                                        <strong>+0.25</strong>
                                    </div>
                                    <Link className="btn btn-primary" role="button" to={'/survey/' + process.env.REACT_APP_SELF_ASSESSMENT_SURVEY_ID}>{surveyInProgress ? 'Continue' : 'Start Activity'}</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
