import React, { useState, useEffect } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';

export default function StrategyChart(props: any) {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const [datasets, setDatasets] = useState<any>();

    let options: any = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
        base: 0,
        barPercentage: 0.7,
        minBarLength: 4,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    padding: 20
                }
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Self-Assessment Date'
                },
                grid: {
                    display: false
                },
                border: {
                    width: 2,
                    color: '#6E757C',
                    z: 2
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Number of Strategies'
                },
                grid: {
                    display: false
                },
                border: {
                    width: 2,
                    color: '#6E757C',
                    z: 2
                }
            }
        }
    };

    useEffect(() => {
        const data = parseData(props.user.strategy_scores); // Assuming the data is passed as a prop
        setDatasets(data);
    }, []);

    const parseData = (data: { score: number, self_assessment_id: number, created_at: string }[]) => {
        const scoresCount: { [key: string]: { [key: number]: number } } = {};
        const labels: string[] = [];
        data.forEach(item => {
            if (!scoresCount[item.created_at]) {
                scoresCount[item.created_at] = { 0: 0, 1: 0, 2: 0, 3: 0};
                labels.push(item.created_at);
            }
            scoresCount[item.created_at][item.score]++;
        });
    
        labels.reverse();

        const datasets = [
            {
                label: 'Area for Growth',
                data: labels.map(label => scoresCount[label][0]),
                backgroundColor: '#FFAA46'
            },
            {
                label: 'Developing',
                data: labels.map(label => scoresCount[label][1]),
                backgroundColor: '#F6CA56'
            },
            {
                label: 'Skilled',
                data: labels.map(label => scoresCount[label][2]),
                backgroundColor: '#A7DE61'
            },
            {
                label: 'Accomplished',
                data: labels.map(label => scoresCount[label][3]),
                backgroundColor: '#65C775'
            },
        ];

        let formattedLabels = labels.map(label => format(new Date(label), 'MM/dd/yyyy'));
    
        return {
            labels: formattedLabels,
            datasets: datasets,
        };
    }

    return (
        <div className="chart-container" style={{'position': 'relative', 'minHeight': '320px'}}>
            { datasets && <Bar options={options} data={datasets} /> }
        </div>
    );
};