import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import InviteUser from '../user/invite-user';
import EditUser from '../user/edit-user';
import DeleteUser from '../user/delete-user';
import { ReactComponent as CheckIcon } from '../../images/icons/check.svg';


export default function UserResult(props: any) {

	let result = props.result;

	const authUser = JSON.parse(localStorage.getItem('user')!);

	useEffect(() => {
		console.log(authUser);
	}, [authUser]);

	const handleReload = () => {
		props.handleReload(true);
	}

	return (
		<tr>
			{authUser.is_admin ?
				<td><a href={'users/' + result.id}>{result.id}</a></td>
				:
				<td>{result.id}</td>
			}
			<td>{result.first_name} {result.last_name}</td>
			<td>{result.email}</td>
			<td>{result.school}</td>
			{
				authUser.is_admin ?
					<>
						<td>{result.cohort}</td>
						<td className="text-center">{result.is_coach ? <CheckIcon /> : ''}</td>
						<td className="text-center">{result.is_admin ? <CheckIcon /> : ''}</td>
					</>
					:
					<></>
			}
			{
				authUser.is_coach ?
					<>

						<td>
							{
								authUser.id !== result.id ?
									<InviteUser user={result} handleReload={handleReload} />
									:
									<></>
							}

						</td>
					</>
					:
					<></>
			}
			{
				authUser.is_admin ?
					<td>
						<div className="d-flex">
							<EditUser user={result} handleReload={handleReload} />
							<DeleteUser user={result} handleReload={handleReload} />
						</div>

					</td>
					:
					<></>
			}

		</tr>
	)
}