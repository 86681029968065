import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../images/maximize-logo.svg';
import { updateMe } from '../_services/user';
import { getTerms, getMyConsents, saveMyConsent } from '../_services/user-consent';
import TermsSection from '../components/user-consent/terms-section';
import { te } from "date-fns/locale";

export default function AgreeToTermsPage(props: any) {

	document.title = 'Confidentiality Statement | Maximize Project';

	const [consentMtmIsChecked, setConsentMtmIsChecked] = useState<boolean>(false);
	const [consentResearchIsChecked, setConsentResearchIsChecked] = useState<boolean>(false);
	const [termsIsChecked, setTermsIsChecked] = useState<boolean>(false);
	const [terms, setTerms] = useState<any>('');
	const [myConsents, setMyConsents] = useState<any>('');
	const [elements, setElements] = useState<any>();
	const [isReady, setIsReady] = useState<boolean>(false);
	const [user, setUser] = useState<any>();

	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	let navigate = useNavigate();

	useEffect(() => {
		getCurrentUser();
		gatherData();
	}, []);

	useEffect(() => {
		localStorage.setItem('user', JSON.stringify(user));
	}, [user]);

	useEffect(() => {
		if (terms.length > 0) {
			buildPage();
		}
	}, [myConsents]);

	const getCurrentUser = () => {
		let userJSON = JSON.parse(localStorage.getItem('user')!);
		setUser(userJSON);
	}

	const gatherData = () => {
		getTerms().then(resp => {
			setTerms(resp.data.data);
			getMyConsents().then(resp => {
				setMyConsents(resp.data.data);
				setUser((prevState: any) => {
					return {
						...prevState,
						agreed_to_terms: resp.data.data[0] ? resp.data.data[0].status : 0,
						consent_to_observe_mtm: resp.data.data[1] ? resp.data.data[1].status : 0,
						consent_to_observe_research: resp.data.data[2] ? resp.data.data[2].status : 0,
					}
				})
			}).catch(err => {
				console.log(err);
			});
		}).catch(err => {
			console.log(err);
		});
	}

	const buildPage = () => {
		let el = terms.filter((item: any) => item.name === 'Confidentiality Statement').map(function (item: any, index: number) {
			return <TermsSection key={index} item={item} status={myConsents[index]?.status} reload={handleReload} />
		});
		setIsReady(true);
		setElements(el);
	}

	const handleReload = (event: any) => {
		gatherData();
	}

	const handleSubmit = (event: any) => {
		navigate('../workflow-routing', { replace: true });
	}

	return (
		isReady ?
			<div id="agree-to-terms">
				<Row className="justify-content-center">
					<Col xs={12} lg={8} xl={6}>
						<Logo id="logo" />
						<h3>Confidentiality Statement</h3>
						<p>Your privacy is of the upmost importance. Your responses will be kept confidential. Others in your building will not have access to your responses unless you share it with them. We hope you can use this private space to engage in meaningful self-reflection, growth, and professional learning to positively impact students in your building.</p>
						<h3>Participation in Research</h3>
						<p>As part of our research project, we seek to learn about ways we can maximize educators' use of equity-focused positive behavior supports. Please click below to authorize the Ohio University research team to use your platform data for research purposes. We will keep your data confidential. Although you used your email to log onto the technology-based resource, no identifying information will be downloaded with any of your responses. Instead, you will be given a code number. Details that could identify you will not be shared with anyone in your school without your permission, with anyone else outside the research team, or used in any publication of results.</p>
						{elements}
					</Col>
				</Row>
				<div className="action-button-container">
					<Container>
						<Row className="justify-content-center">
							<Col xs={12} xl={9}>
								<div className="d-flex justify-content-between">
									<Button variant="secondary" onClick={() => navigate('/logout')}>Log Out</Button>
									
									<Button variant="primary" onClick={handleSubmit} disabled={ myConsents[0] == null ? true : false}>Continue</Button>
								</div>
							</Col>
						</Row>
					</Container>

				</div>
			</div>
			:
			<></>
	)
}
